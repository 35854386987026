<template>
  <div class="squad-stats">
    <div class="squad-stats__column">
      <div class="squad-stats__title">
        <div :class="bem('squad-stats__icon', { type: 'coins' })"></div>
        <div class="squad-stats__title-text">
          {{ $t('squadStats.income') }}
        </div>
      </div>
      <div class="squad-stats__value">
        <div class="squad-stats__number">{{ valueIncome }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumber } from '@/lib/utils'
export default {
  name: 'SquadStats',
  props: {
    income: {
      type: Number,
      default: 0,
    },
    members: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    valueIncome() {
      return formatNumber(Number(this.income) || 0)
    },
    membersValue() {
      return formatNumber(Number(this.members) || 0)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.squad-stats {
  display: flex;
  color: white;
  align-items: center;
  padding: 16rem 0;

  &__column {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  &__separator {
    width: 2px;
    background: white;
    opacity: 0.2;
    height: 40rem;
    flex-shrink: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 4rem;
  }

  &__title-text {
    font-size: 14rem;
    opacity: 0.6;
    text-align: center;
    text-transform: uppercase;
    line-height: 1em;
  }

  &__value {
    font-size: 18rem;
    display: flex;
    align-items: center;
  }

  &__icon {
    flex-shrink: 0;
    width: 20rem;
    height: 20rem;
    margin-right: 5rem;
    background: center no-repeat;
    background-size: contain;
    position: relative;
    bottom: -0.5rem;

    &_type {
      &_coins {
        margin-right: 2rem;
        background-image: url(@/assets/icons/coins-yellow.png);
        filter: drop-shadow(0 0 10rem rgba($color-yellow, 0.4));
      }

      &_people-3 {
        background-image: url(@/assets/icons/people-3.svg);
        filter: drop-shadow(0 0 5rem rgba($color-cyan, 0.3));
      }
    }
  }

  &__number {
    font-size: 40rem;
    line-height: 1em;
    font-weight: bold;
    margin-top: 0.1em;
  }

  &__indicator {
    font-size: 13rem;
    display: flex;
    align-items: center;
    gap: 4rem;

    &_type {
      &_up {
        color: $color-green;

        .squad-stats__indicator-icon {
          background-image: url(@/assets/icons/arrow-up-green.svg);
          filter: drop-shadow(0rem 0px 4rem $color-green);
        }
      }

      &_down {
        color: $color-red;

        .squad-stats__indicator-icon {
          background-image: url(@/assets/icons/arrow-down-red.svg);
          filter: drop-shadow(0rem 0px 4rem $color-red);
        }
      }
    }
  }

  &__indicator-icon {
    flex-shrink: 0;
    width: 1.2em;
    height: 1.2em;
    background: center no-repeat;
    background-size: contain;
  }

  &__indicator-text {
  }
}
</style>
