<template>
  <div></div>
</template>

<script>
import eventBus from '@/lib/eventBus'

export default {
  data() {
    return {
      audioContext: null,
      bufferList: {},
      currentSource: null,
    }
  },
  methods: {
    async loadAudio(url) {
      const response = await fetch(url)
      const arrayBuffer = await response.arrayBuffer()
      return this.audioContext.decodeAudioData(arrayBuffer)
    },
    async initSounds() {
      const audioFiles = {
        collect_coin: require('@/assets/audio/collect_coin.mp3'),
        collect_energy: require('@/assets/audio/collect_energy.mp3'),
        crush: require('@/assets/audio/crush.mp3'),
        hit1: require('@/assets/audio/hit-1.mp3'),
        hit2: require('@/assets/audio/hit-2.mp3'),
        hit4: require('@/assets/audio/hit-4.mp3'),
        spawn: require('@/assets/audio/spawn.mp3'),
        reverse: require('@/assets/audio/reverse.mp3'),
      }

      for (const [key, url] of Object.entries(audioFiles)) {
        this.bufferList[key] = await this.loadAudio(url)
      }
    },
    playSound(soundKey) {
      if (this.currentSource) {
        this.currentSource.stop()
      }
      const buffer = this.bufferList[soundKey]
      if (buffer) {
        const source = this.audioContext.createBufferSource()
        source.buffer = buffer
        source.connect(this.audioContext.destination)
        source.start(0)
        this.currentSource = source
      }
    },
  },
  async created() {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
    await this.initSounds()
    eventBus.on('play-sound', this.playSound)
  },
  beforeUnmount() {
    eventBus.off('play-sound', this.playSound)
    if (this.currentSource) {
      this.currentSource.stop()
    }
    this.audioContext.close()
  },
}
</script>
