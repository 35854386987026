<template>
  <div class="game-content">
    <div v-if="activeTabId === 'farm'" class="game-content__content">
      <TasksList
        class="game-content__tasks-list"
        @join-squad-click="activeTabId = 'top'"
      />

      <div :class="bem('game-content__line', { mb: true })" />

      <LootBoxPreview />
    </div>
    <div v-if="activeTabId === 'mySquad'" class="game-content__content">
      <MySquadManager />
    </div>
    <div v-else-if="activeTabId === 'top'" class="game-content__content">
      <SquadsList
        class="game-content__squads-list"
        @squad-created="activeTabId = 'mySquad'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SquadsList from '@/components/SquadsList.vue'
import TasksList from '@/components/TasksList.vue'
import LootBoxPreview from '@/components/BoxPreview.vue'
import MySquadManager from './MySquadManager.vue'

export default {
  name: 'GameContent',
  components: {
    LootBoxPreview,
    TasksList,
    SquadsList,
    MySquadManager,
  },
  data: () => ({
    activeTabId: '',
    firstLoad: true,
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isSquadOwner']),
    tabs() {
      return [
        this.isSquadOwner
          ? {
              id: 'mySquad',
              title: this.$t('gameContent.tabMySquad'),
            }
          : {
              id: 'farm',
              title: this.$t('gameContent.tabFarm'),
            },
        {
          id: 'top',
          title: this.$t('gameContent.tabTop'),
        },
      ]
    },
  },
  watch: {
    activeTabId(id) {
      if (this.firstLoad) {
        this.firstLoad = false
        return
      }
      if (!id) return

      if (id === 'farm') {
        this.$store.dispatch('eventManager/trackEvent', {
          eventType: 'tabFarmClicked',
        })
      } else if (id === 'mySquad') {
        this.$store.dispatch('eventManager/trackEvent', {
          eventType: 'squadOwner_tabMySquadClicked',
        })
      } else if (id === 'top') {
        if (this.isSquadOwner) {
          this.$store.dispatch('eventManager/trackEvent', {
            eventType: 'squadOwner_tabRatingClicked',
          })
        } else {
          this.$store.dispatch('eventManager/trackEvent', {
            eventType: 'tabRankingsClicked',
          })
        }
      }
    },
  },
  mounted() {
    this.activeTabId = this.tabs[0].id
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.game-content {
  --padding-left-right: 16rem;

  display: flex;
  flex-direction: column;

  &__tabs {
  }

  &__content {
    flex: 1;
    padding-bottom: var(--padding-left-right);
    border: 3px solid $color-cyan;
    background-color: rgba($color-violet-dark, 0.95);
  }

  &__tasks-list {
    margin: 0 var(--padding-left-right);
  }

  &__my-squad-head {
    margin: 0 var(--padding-left-right) 16rem;
  }

  &__squads-list {
    --padding-x: var(--padding-left-right);

    margin-top: 10rem;
  }

  &__line {
    margin: 0 var(--padding-left-right);
    height: 2px;
    background-color: $color-cyan;
    box-shadow: 0 0 16px 0 $color-cyan-dark;

    &_mt {
      margin-top: 20rem;
    }

    &_mb {
      margin-bottom: 20rem;
    }
  }
}
</style>
