import {
  getUrlParams,
  handleDevelopmentMode,
  handleProductionMode,
  handleMockUser,
} from '@/lib/authTools'

/*

Авторизация в продовом режиме требует window.Telegram.WebApp.initData для получения api токена.
В дев режиме необходимо самостоятельно указать auth_token в localstorage, или при помощи /?setAuthToken={token}.

Для удобства можно запустить приложение с моковым пользоватлем:
/?mock=user
/?mock=memer
/?mock=owner

*/

export default {
  async authorize() {
    const { mockUserType } = getUrlParams()

    if (process.env.NODE_ENV === 'development') {
      if (mockUserType) {
        return handleMockUser(mockUserType)
      }

      return await handleDevelopmentMode()
    }

    return await handleProductionMode()
  },
}
