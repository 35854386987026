<template>
  <div class="app-loader">
    <img class="app-loader__logo" :src="hyperLogoBase64" alt="Hyper logo" />
    <progress-bar
      class="app-loader__launch-progress"
      :value="progress"
      :max-value="100"
      color="cyan"
      size="slim"
    />
  </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue'
import { gsap } from 'gsap'
import { hyperLogoBase64 } from '@/lib/base64images'

export default {
  components: { ProgressBar },
  name: 'AppLoader',
  emits: ['after-loading-finished'],
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hyperLogoBase64,
      progress: 0,
      animation: null,
    }
  },
  watch: {
    loaded: {
      handler(newValue) {
        if (newValue) {
          this.completeLoading()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.runLoading()
  },
  methods: {
    runLoading() {
      this.animation = gsap.to(this.$data, {
        progress: 70,
        duration: 2,
        ease: 'power2.out',
        onComplete: () => {
          this.animation = gsap.to(this.$data, {
            progress: 80,
            duration: 3,
            ease: 'power1.out',
            onComplete: () => {
              this.animation = gsap.to(this.$data, {
                progress: 95,
                duration: 10,
                ease: 'linear',
              })
            },
          })
        },
      })
    },
    completeLoading() {
      if (this.animation) {
        this.animation.kill()
      }

      gsap.to(this.$data, {
        progress: 100,
        duration: 1,
        ease: 'power1.out',
        onComplete: () => {
          this.$emit('after-loading-finished')
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.app-loader {
  background: $color-blue-black;
  padding: 10rem 10rem 20rem;
  width: calc(100vw - 40rem);
  border-radius: 24rem;

  &__logo {
    width: 100%;
    margin-bottom: 5rem;
  }

  &__launch-progress {
    position: relative;
    z-index: 10;
    margin: 0 10rem;
  }
}
</style>
