<template>
  <PopupBottom class="popup-leave-squad" @close="$emit('close')">
    <div class="popup-leave-squad__content">
      <div class="popup-leave-squad__head">
        {{ $t('popupLeaveSquad.head') }}
      </div>

      <SquadItem
        class="popup-leave-squad__squad-item"
        :squad="squad"
        is-preview
      />

      <div class="popup-leave-squad__buttons">
        <ButtonAction
          class="popup-leave-squad__button"
          size="big"
          color="green"
          @click="!leaveLoading && $emit('close')"
        >
          {{ $t('popupLeaveSquad.stay') }}
        </ButtonAction>

        <ButtonAction
          class="popup-leave-squad__button"
          size="big"
          color="pink"
          :loading="leaveLoading"
          @click="leaveSquad()"
        >
          {{ $t('popupLeaveSquad.leave') }}
        </ButtonAction>
      </div>
    </div>
  </PopupBottom>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PopupBottom from '@/components/PopupBottom.vue'
import ButtonAction from '@/components/ButtonAction.vue'
import api from '@/api'

export default {
  name: 'PopupLeaveSquad',
  components: {
    ButtonAction,
    PopupBottom,
  },
  emits: ['close', 'leave'],
  props: {
    squad: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    leaveLoading: false,
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('constants', ['constants']),
  },
  methods: {
    ...mapActions('user', ['fetchUser']),
    getRemainingEnergyTime() {
      return this.$store.getters['user/getRemainingEnergyTime']()
    },
    async leaveSquad() {
      this.leaveLoading = true
      await api.squads.leaveSquad()
      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'leftSquad',
      })
      await this.fetchUser()
      this.leaveLoading = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.popup-leave-squad {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__head {
    margin-bottom: 5rem;
    font-size: 24rem;
    font-weight: 600;
    line-height: 1.4;
  }

  &__squad-item {
  }

  &__energy-text {
    display: flex;
    align-items: center;
    margin-bottom: 10rem;
    font-size: 24rem;
    font-weight: 500;
    line-height: 1.25;
  }

  &__timer {
    font-weight: bold;
  }

  &__text {
    font-size: 16rem;
    font-weight: 600;
    line-height: 1.4;
  }

  &__buttons {
    display: flex;
    gap: 8rem;
    width: 320rem;
    margin-top: 15rem;
  }

  &__button {
    flex-grow: 1;
  }
}
</style>
