<template>
  <div class="box-game-scene" @pointerdown="hit()" @touchstart="onTouchStart">
    <div class="box-game-scene__content">
      <img
        v-show="isRewardVisible"
        ref="rewardHead"
        class="box-game-scene__reward-head"
        :src="$t('boxGameScene.rewardHead')"
      />

      <ProgressBar
        class="box-game-scene__health"
        ref="health"
        :max-value="maxHealth"
        :value="health"
        color="green-intense"
        no-icon
        max-value-visible
        size="normal"
      />

      <div class="box-game-scene__hit-power-container">
        <img
          ref="hitPower1"
          class="box-game-scene__hit-power"
          :src="$t('boxGameScene.normal')"
        />
      </div>
      <div class="box-game-scene__hit-power-container">
        <img
          ref="hitPower2"
          class="box-game-scene__hit-power"
          :src="$t('boxGameScene.good')"
        />
      </div>
      <div class="box-game-scene__hit-power-container">
        <img
          ref="hitPower4"
          class="box-game-scene__hit-power"
          :src="$t('boxGameScene.perfect')"
        />
      </div>

      <div ref="box" :class="bem('box-game-scene__box', { damage })">
        <div
          v-show="isExplodeVisible"
          ref="boxExplode"
          :class="bem('box-game-scene__box-explode', { type: explodeType })"
        />
        <div
          v-show="isAppearVisible"
          ref="boxAppear"
          class="box-game-scene__box-appear"
        />
      </div>

      <div
        v-if="hasEnergy && isGameTipVisible"
        ref="gameTip"
        class="box-game-scene__bottom-container"
      >
        <div class="box-game-scene__tap">
          <img
            class="box-game-scene__tap-image"
            :src="$t('boxGameScene.tap')"
          />

          <EnergyValue size="huge" :value="1" />
        </div>
        <img
          ref="tapIcon"
          class="box-game-scene__tap-icon"
          src="@/assets/images/hand-lined.png"
        />
      </div>
      <div
        v-else-if="!hasEnergy && !isRewardVisible && !isExplodeVisible"
        ref="noEnergyContainer"
        class="box-game-scene__bottom-container"
      >
        <img
          class="box-game-scene__no-energy-icon"
          src="@/assets/images/no-energy.png"
        />
        <img
          class="box-game-scene__no-energy-text"
          :src="$t('boxGameScene.textNoEnergy')"
        />

        <ButtonAction
          class="box-game-scene__button-invite-friend"
          size="big"
          icon="energy-green"
          @click="goToFarmEnergy()"
        >
          {{ $t('boxGameScene.buttonFarmEnergyText') }}
        </ButtonAction>
      </div>

      <div
        v-show="isRewardVisible"
        ref="rewardContainer"
        class="box-game-scene__reward-container"
      >
        <div class="box-game-scene__reward-value">
          {{ formatNumber(crushedValue) }}
        </div>

        <ButtonAction
          class="box-game-scene__button-collect-reward"
          size="big"
          color="yellow"
          :loading="collectLoading"
          @click="collectReward()"
        >
          {{ $t('boxGameScene.buttonCollectReward') }}
        </ButtonAction>
      </div>
    </div>

    <div
      ref="scaleContainer"
      :class="bem('box-game-scene__scale-container', { lock: lockHit })"
    >
      <div class="box-game-scene__scale">
        <img
          v-if="hasEnergy"
          class="box-game-scene__scale-image"
          src="@/assets/images/box-game-scale.png"
        />
        <img
          v-else
          class="box-game-scene__scale-image"
          :src="$t('boxGameScene.scaleNoEnergy')"
        />

        <div v-if="hasEnergy" class="box-game-scene__scale-track">
          <!-- <div
            class="box-game-scene__scale-line"
            v-for="(item, index) in scaleLines"
            :key="index"
            :style="{ top: `${item * 100}%` }"
          >
            {{ item }}
          </div> -->
          <div
            ref="scaleSlider"
            class="box-game-scene__scale-slider"
            :style="{ top: sliderTopPosition }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ProgressBar from '@/components/ProgressBar.vue'
import EnergyValue from '@/components/EnergyValue.vue'
import gsap from 'gsap'
import ButtonAction from '@/components/ButtonAction.vue'
import api from '@/api'
import { formatNumber } from '@/lib/utils'
import eventBus from '@/lib/eventBus'

const MAX_DAMAGE = 2
const BASE_DURATION = 1.5
const MIN_DURATION_DECREASE = 0
const MAX_DURATION_DECREASE = 0.6

export default {
  name: 'BoxGameScene',
  components: { ButtonAction, EnergyValue, ProgressBar },
  emits: ['before-crush', 'reload'],
  data: () => ({
    isHitTweenPlaying: false,
    isSpawnPlaying: false,
    isGameTipVisible: true,
    crushedValue: 0,
    isExplodeVisible: false,
    isAppearVisible: false,
    isRewardVisible: false,
    collectLoading: false,
    hits: [],
    explodeType: '',
    currentSpeed: BASE_DURATION,
    currentPosition: 0,
    direction: 'down',
    lockHit: true,
    // Для отладки
    scaleLines: [0.17, 0.5, 0.77],
  }),
  watch: {
    currentPosition(newValue, oldValue) {
      const thresholds = [0.17, 0.5, 0.77]
      thresholds.forEach((threshold) => {
        if (
          (oldValue < threshold && newValue >= threshold) ||
          (oldValue > threshold && newValue <= threshold)
        ) {
          this.updateSliderSpeed()
        }
      })
    },
  },
  mounted() {
    this.direction = 'down'
    this.animateSlider()
    this.animateHand()

    if (this.health === this.maxHealth) {
      this.appear()
    }
  },

  unmounted() {
    if (this._scaleSliderTween) {
      this._scaleSliderTween.kill()
    }
    if (this._handTween) {
      this._handTween.kill()
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('constants', ['constants']),
    ...mapGetters(['energyLootboxLink']),
    totalHits() {
      return this.hits.reduce((acc, hit) => acc + hit, 0)
    },
    maxHealth() {
      return this.constants.boxHealth
    },
    health() {
      return Math.max(this.maxHealth - this.totalHits, 0)
    },
    damage() {
      const ratio = 1 - this.health / this.maxHealth

      return Math.round(ratio * MAX_DAMAGE)
    },
    hasEnergy() {
      return this.energy > 0
    },
    canHit() {
      return (
        this.hasEnergy &&
        this.health > 0 &&
        !this.isHitTweenPlaying &&
        !this.lockHit &&
        !this.isAppearVisible
      )
    },
    energy() {
      return this.user.energy
    },
    sliderTopPosition() {
      return `${this.currentPosition * 100}%`
    },
  },
  methods: {
    formatNumber,
    generateRandomNumber(min, max) {
      return Math.random() * (max - min) + min
    },
    async appear() {
      this.isAppearVisible = true

      await this.$nextTick()

      const tl = gsap.timeline()
      const duration = 0.3

      tl.to(this.$refs.boxAppear, {
        backgroundPositionX: '100%',
        duration: 0.7,
        ease: 'steps(15)',
        onComplete: () => {
          this.isAppearVisible = false
        },
      })
      tl.from(this.$refs.scaleContainer, {
        x: '80rem',
        duration: duration,
      })
      tl.from(
        this.$refs.health.$el,
        {
          y: '-100rem',
          opacity: 0,
          duration: duration,
        },
        '<'
      )

      if (this.$refs.gameTip) {
        tl.from(
          this.$refs.gameTip,
          {
            y: '100rem',
            opacity: 0,
            duration: duration,
          },
          '<'
        )
      } else if (this.$refs.noEnergyContainer) {
        tl.from(
          this.$refs.noEnergyContainer,
          {
            y: '150rem',
            opacity: 0,
            duration: duration,
          },
          '<'
        )
      }

      tl.then(() => {
        this.lockHit = false
      })
    },
    animateSlider() {
      if (!this.hasEnergy) {
        return
      }

      if (this._scaleSliderTween) {
        this._scaleSliderTween.kill()
      }

      const endPosition = this.direction === 'down' ? 1 : 0
      const duration =
        this.currentSpeed * Math.abs(this.currentPosition - endPosition)

      this._scaleSliderTween = gsap.to(this, {
        currentPosition: endPosition,
        ease: 'linear',
        duration: duration / 3,
        onComplete: this.toggleDirection,
      })
    },

    toggleDirection() {
      this.direction = this.direction === 'down' ? 'up' : 'down'
      this.animateSlider()
    },
    checkSliderPosition() {
      this.currentPosition = this._scaleSliderTween.progress()
    },
    updateSliderSpeed() {
      const randomChance = Math.random()

      if (this._scaleSliderTween) {
        this._scaleSliderTween.kill()
      }

      const randomIncrease = this.generateRandomNumber(
        MIN_DURATION_DECREASE,
        MAX_DURATION_DECREASE
      )

      this.currentSpeed = BASE_DURATION - randomIncrease
      this.animateSlider()
    },
    animateHand() {
      this._handTween = gsap.to(this.$refs.tapIcon, {
        scale: 0.7,
        ease: 'linear',
        repeat: -1,
        duration: 0.3,
        yoyo: true,
      })
    },
    getHitPower(progress) {
      if (progress < 0.105) {
        return 4
      } else if (progress < 0.818) {
        return 1
      }

      return 2
    },
    async hit() {
      if (!this.canHit) {
        return
      }

      this.isHitTweenPlaying = true
      this.lockHit = true

      const hitPower = this.getHitPower(this.currentPosition)

      this.hits.push(hitPower)
      if (this.totalHits < this.maxHealth || this.user.energy > 1) {
        this.$store.commit('user/setUserEnergy', this.user.energy - 1)
      }

      this._scaleSliderTween.pause()

      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'hitLootbox',
        eventProperties: { power: hitPower },
      })

      const elementsMap = {
        1: this.$refs.hitPower1,
        2: this.$refs.hitPower2,
        4: this.$refs.hitPower4,
      }
      const duration = 1
      const hitPowerEl = elementsMap[hitPower]

      eventBus.emit('play-sound', `hit${hitPower}`)

      this.safeVibrate()

      gsap.to(hitPowerEl, {
        duration: duration / 2,
        opacity: 1,
        yoyo: true,
        repeat: 1,
        ease: 'power1.inOut',
      })
      const hitPowerAnimPromise = gsap
        .to(hitPowerEl, {
          y: '-150rem',
          duration: duration,
          ease: 'power1.inOut',
          clearProps: true,
        })
        .then()

      if (this.isGameTipVisible) {
        this._handTween.kill()

        gsap.to(this.$refs.gameTip, {
          duration: 0.5,
          opacity: 0,
          ease: 'power1.inOut',
          onComplete: () => {
            this.isGameTipVisible = false
          },
        })
      }

      const colorsMap = {
        1: '#42F4FF',
        2: '#CEBB49',
        4: '#FF4949',
      }

      gsap
        .timeline()
        .to(this.$refs.box, {
          duration: 0.15,
          scale: 0.75,
          ease: 'power1.inOut',
          yoyo: true,
          repeat: 1,
        })
        .to(
          this.$refs.box,
          {
            duration: 0.05,
            x: '+=10',
            ease: 'power1.inOut',
            yoyo: true,
            repeat: 5,
          },
          0
        )
        .to(
          this.$refs.box,
          {
            duration: 0.05,
            x: '-=10',
            ease: 'power1.inOut',
            yoyo: true,
            repeat: 5,
          },
          0.25
        )
        .to(
          this.$refs.box,
          {
            duration: 0.075,
            filter: `drop-shadow(0 0 100rem ${colorsMap[hitPower]})`,
            ease: 'power1.inOut',
            yoyo: true,
            repeat: 3,
          },
          0
        )
        .to(this.$refs.box, {
          duration: 0,
          x: '0',
        })

      if (this.totalHits >= this.maxHealth) {
        const apiCallPromise = api.users.crashBox({ hits: this.hits })

        apiCallPromise.then(({ energy, coins, tokens, tokensType }) => {
          if (energy != null && !isNaN(Number(energy))) {
            this.$store.commit('user/setUserEnergy', energy)
          }

          if (coins > 0) {
            this.crushedValue = coins
            this.explodeType = 'coin'
            this.crush()
            this.$store.dispatch('eventManager/trackEvent', {
              eventType: 'hyperCoinCollected',
              eventProperties: { amount: coins },
            })
          } else if (tokens > 0 && tokensType === 'ton') {
            this.crushedValue = parseFloat(tokens).toString()
            this.$store.dispatch('eventManager/trackEvent', {
              eventType: 'tonCollected',
              eventProperties: { amount: tokens },
            })
            this.explodeType = 'ton'
            this.crush()
          } else {
            this.$router.push({ name: 'error' })
          }
        })

        await Promise.all([hitPowerAnimPromise, apiCallPromise])
      } else {
        await hitPowerAnimPromise
      }

      this.isHitTweenPlaying = false

      setTimeout(() => {
        this.lockHit = false
      }, 300)

      if (this.crushedValue === 0) {
        this._scaleSliderTween.resume()
      }
    },
    crush() {
      this.$emit('before-crush')

      eventBus.emit('play-sound', 'crush')

      gsap.to(this.$refs.scaleContainer, {
        opacity: 0,
        duration: 0.5,
      })
      // TODO TypeError Cannot read properties of null (reading '$el')
      // GSAP target null not found.
      if (this.$refs.health.$el) {
        gsap.to(this.$refs.health.$el, {
          opacity: 0,
          duration: 0.5,
        })
      }

      const tl = gsap.timeline()

      this.isExplodeVisible = true

      const stepsMap = {
        coin: 23,
        ton: 16,
      }

      tl.to(this.$refs.boxExplode, {
        backgroundPositionX: '100%',
        duration: 1,
        ease: `steps(${stepsMap[this.explodeType]})`,
        onComplete: () => {
          this.isRewardVisible = true

          if (this.crushedValue === 0) {
            tl.pause()

            const unwatch = this.$watch('crushedValue', () => {
              unwatch()
              tl.resume()
            })
          }
        },
      })
      tl.to(this.$refs.box, {
        scale: 0.4,
        duration: 0.2,
      })
      tl.fromTo(
        this.$refs.rewardHead,
        {
          opacity: 0,
          y: '-40rem',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.3,
          ease: 'power2.out',
        }
      )
      tl.fromTo(
        this.$refs.rewardContainer,
        {
          opacity: 0,
          y: '40rem',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.3,
          ease: 'power2.out',
        },
        '-=0.2'
      )
    },
    goToFarmEnergy() {
      window.open(this.energyLootboxLink, '_blank')
    },
    async collectReward() {
      this.lockHit = true
      this.collectLoading = true
      await this.$store.dispatch('user/fetchUser')
      eventBus.emit('play-sound', 'collect_coin')

      gsap.to(this.$el, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          this.hits = []
          setTimeout(() => {
            this.$emit('reload')
            this.lockHit = false
          }, 700)
        },
      })
    },
    onTouchStart(e) {
      if (this.canHit) {
        e.preventDefault()
      }
    },
    safeVibrate() {
      if (navigator.vibrate) {
        try {
          navigator.vibrate(200)
        } catch (e) {
          console.error(e)
        }
      } else {
        try {
          window.Telegram.WebApp.HapticFeedback.impactOccurred('medium')
        } catch (e) {
          console.error(e)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.box-game-scene {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__reward-head {
    position: absolute;
    z-index: 20;
    top: 8rem;
    left: 50%;
    transform: translateX(-50%);
    width: 347rem;
    opacity: 0;
  }

  &__health {
    width: 170rem;
    margin-bottom: 12rem;
  }

  &__image {
    width: 165rem;
  }

  &__hit-power-container {
    position: absolute;
    z-index: 20;
    top: -70%;
    left: 50%;
    transform: translateX(-50%);
    height: 200rem;
  }

  &__hit-power {
    height: 100%;
    opacity: 0;
  }

  &__box {
    position: relative;
    z-index: 10;
    width: 166rem;
    height: 167rem;
    background: center no-repeat;
    background-size: contain;

    &_damage {
      &_0 {
        background-image: url(@/assets/images/box.png);
      }

      &_1 {
        background-image: url(@/assets/images/box-1.png);
      }

      &_2 {
        background-image: url(@/assets/images/box-2.png);
      }
    }
  }

  &__box-appear {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 510rem;
    height: 510rem;
    background: left no-repeat url(@/assets/images/box-appear-sprite.png);
    background-size: auto 100%;
  }

  &__box-explode {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 510rem;
    height: 510rem;
    background-size: auto 100%;
    background-position: left;
    background-repeat: no-repeat;

    &_type {
      &_coin {
        background-image: url(@/assets/images/box-explode-coin-sprite.png);
      }
      &_ton {
        background-image: url(@/assets/images/box-explode-ton-sprite.png);
      }
    }
  }

  &__bottom-container {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__tap {
    display: flex;
    align-items: center;
  }

  &__tap-image {
    width: 130rem;
  }

  &__tap-icon {
    width: 106rem;
  }

  &__no-energy-icon {
    width: 120rem;
  }

  &__no-energy-text {
    height: 77rem;
    margin-top: -40rem;
  }

  &__button-invite-friend {
    width: 300rem;
  }

  &__reward-container {
    position: absolute;
    z-index: 20;
    top: 88%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
  }

  &__reward-value {
    margin-bottom: 30rem;
    font-size: 42rem;
    font-weight: bold;
  }

  &__button-collect-reward {
    width: 300rem;
  }

  &__scale-container {
    position: absolute;
    top: 0;
    right: 20rem;
    height: 100%;
    display: flex;
    align-items: center;
    transition: 0.15s ease-in opacity;

    &_lock {
      opacity: 0.6;
    }
  }

  &__scale {
    position: relative;
    width: 36rem;
  }

  &__scale-image {
    width: 100%;
  }

  &__scale-track {
    position: absolute;
    top: 9%;
    right: 100%;
    width: 0;
    height: 82%;
    // width: 1px;
    // background: red;
  }

  &__scale-line {
    position: absolute;
    top: 0;
    right: 0;
    height: 1px;
    width: 50rem;
    background: red;
    font-size: 12rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: red;
  }

  &__scale-slider {
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 10rem 0 10rem 12rem;
    border-color: transparent transparent transparent $color-pink;
    transform: translateY(-50%);
  }
}
</style>
