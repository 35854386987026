<template>
  <div class="my-squad-manager">
    <HeadDoted class="my-squad-manager__header-dotted">
      <template #text>{{ $t('mySquadManager.head') }}</template>
      <template #action>
        <ButtonSwithcer v-model="selectedType" :items="sortList" />
      </template>
    </HeadDoted>

    <SquadStats :income="squadFeeCoins" class="my-squad-manager__squad-stats" />

    <div class="my-squad-manager__button-container">
      <ButtonAction
        @click="inviteFriend"
        class="my-squad-manager__button"
        size="big"
        color="green"
      >
        {{ $t('mySquadManager.inviteButton') }}
      </ButtonAction>
    </div>

    <SquadItem
      class="my-squad-manager__squad-item"
      :squad="squad"
      :hide-items="['fee', 'coins']"
    />

    <SquadFeeRagne
      v-model="fee"
      :min="constants.squadMinFee"
      :max="constants.squadMaxFee"
      :loading="loadingChange"
      @mouse-up="changeFee"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import api from '@/api'
import ButtonAction from './ButtonAction.vue'
import ButtonSwithcer from './ButtonSwithcer.vue'
import HeadDoted from './HeadDoted.vue'
import SquadFeeRagne from './SquadFeeRagne.vue'
import SquadItem from './SquadItem.vue'
import SquadStats from './SquadStats.vue'

export default {
  components: {
    HeadDoted,
    ButtonSwithcer,
    SquadStats,
    ButtonAction,
    SquadItem,
    SquadFeeRagne,
  },
  name: 'MySquadManager',
  data: () => ({
    fee: 1.42,
    selectedType: null,
    loadingChange: false,
    ladingSquads: true,
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['weeklyTopPosition', 'dailyTopPosition']),
    ...mapGetters('constants', ['constants']),
    ...mapState('squadsTop', ['activeType']),
    sortList() {
      return [
        {
          id: 'daily',
          title: this.$t('sort.today'),
          feeCoins: this.squad.dailyFeeCoins,
        },
        {
          id: 'weekly',
          title: this.$t('sort.week'),
          feeCoins: this.squad.weeklyFeeCoins,
        },
      ]
    },
    squadIncome() {
      return this.sortList.find((i) => i.id === this.selectedType)?.coins ?? '-'
    },
    squadFeeCoins() {
      return (
        this.sortList.find((i) => i.id === this.selectedType)?.feeCoins ?? '-'
      )
    },
    fetchSquadsList() {
      return this.sortList.find((i) => i.id === this.selectedType).handler
    },
    squadInTopPosition() {
      return this.sortList.find((i) => i.id === this.selectedType).topPosition
    },
    squad() {
      return this.user.owningSquad
    },
  },
  watch: {
    selectedType: {
      async handler(type) {
        this.setActiveType(type)
        await this.loadSquads()
      },
    },
  },
  methods: {
    ...mapMutations('squadsTop', ['setActiveType']),
    ...mapActions('squadsTop', ['fetchActiveTop']),
    inviteFriend() {
      this.$store.dispatch('inviteFriend')
      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'squadOwner_inviteToSquadClicked',
      })
    },
    async changeFee() {
      this.loadingChange = true
      await api.squads.setFee(this.fee)
      await this.$store.dispatch('user/fetchUser')
      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'squadOwner_feeChanged',
      })
      this.loadingChange = false
    },
    async loadSquads() {
      this.ladingSquads = true
      await this.fetchActiveTop()
      this.ladingSquads = false
    },
  },
  async created() {
    this.selectedType = this.activeType
    this.fee = this.squad.fee
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.my-squad-manager {
  padding: 24rem 16rem 0;

  &__header-dotted {
  }

  &__squad-stats {
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    padding-bottom: 10rem;
    width: 100%;
  }

  &__button {
    width: 100%;
  }

  &__squad-item {
    margin: 0 16rem;
  }
}
</style>
