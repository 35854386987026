<template>
  <div :class="bem('range-slider', { loading })">
    <div class="range-slider__track">
      <div
        class="range-slider__fill"
        :style="{ width: fillPercentage + '%' }"
      ></div>
      <input
        type="range"
        :min="min"
        :max="max"
        step="0.01"
        v-model="localValue"
        class="range-slider__slider"
        @change="onMouseUp"
      />
      <div
        class="range-slider__thumb"
        :style="{ left: thumbPosition + '%' }"
      ></div>
    </div>
    <SpinnerBlock v-if="loading" class="range-slider__spinner-block" />
  </div>
</template>

<script>
import SpinnerBlock from './SpinnerBlock.vue'
export default {
  components: { SpinnerBlock },
  name: 'RangeSlider',
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 3,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'on-change'],
  computed: {
    fillPercentage() {
      const percentage =
        ((this.localValue - this.min) / (this.max - this.min)) * 100
      return Math.min(100, Math.max(0, percentage))
    },
    thumbPosition() {
      const position =
        ((this.localValue - this.min) / (this.max - this.min)) * 100
      return Math.min(100, Math.max(0, position))
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit('update:modelValue', parseFloat(newValue))
    },
    modelValue(newValue) {
      this.localValue = newValue
    },
  },
  data() {
    return {
      localValue: this.modelValue,
    }
  },
  methods: {
    onMouseUp() {
      this.$emit('mouse-up')
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.range-slider {
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;

  &_loading {
    pointer-events: none;
    .range-slider__track {
      opacity: 0.5;
    }
  }

  &__track {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    background-image: url(@/assets/images/striped-vertical-more-transparent.svg);
    background-size: auto 100%;
    background-repeat: repeat-x;
    background-position: right top;
    border-radius: 5rem;
    margin: 10rem 0;
    position: relative;
    display: flex;
  }

  &__fill {
    height: 23rem;
    background-color: $color-cyan-dark;
    background-image: url(@/assets/images/striped-vertical.svg);
    background-size: auto 100%;
    background-repeat: repeat-x;
    background-position: left top;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5rem;
  }

  &__slider {
    width: 100%;
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    height: 23rem;
    margin: 0;
    opacity: 0;
  }

  &__thumb {
    position: absolute;
    width: 26rem;
    height: 26rem;
    background-color: $color-cyan;
    box-shadow: 0 0 0 4rem darken($color-cyan-dark, 10%);
    border-radius: 50%;
    cursor: pointer;
    transform: translateX(-50%);
    z-index: 2;
    pointer-events: none;
  }

  &__spinner-block {
    position: absolute;
    font-size: 18rem;
  }
}
</style>
