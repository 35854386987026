<template>
  <div class="head-doted">
    <div class="head-doted__text">
      <slot name="text" />
    </div>
    <div class="head-doted__dot"></div>
    <slot name="action" />
  </div>
</template>

<script>
export default {
  name: 'HeadLined',
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.head-doted {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20rem;
  font-size: 18rem;
  line-height: 1.4;
  font-weight: bold;
  text-transform: uppercase;

  &__dot {
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    background: white;
  }
}
</style>
