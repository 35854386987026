import api from '@/api'
import { mockUser, mockMemer, mockOwner } from '@/mocks/user'

export const getUrlParams = (): {
  mockUserType: string | null
  setAuthToken: string | null
} => {
  const urlParams = new URLSearchParams(window.location.search)
  return {
    mockUserType: urlParams.get('mock'),
    setAuthToken: urlParams.get('set-auth-token'),
  }
}

export const getStoredToken = (): string | null =>
  localStorage.getItem('auth_token')

export const handleDevelopmentMode = async () => {
  const { setAuthToken } = getUrlParams()

  if (setAuthToken) {
    localStorage.setItem('auth_token', setAuthToken)
    window.location.assign('/')
  }

  try {
    const user = await api.users.me()
    return { user }
  } catch {
    throw new Error(
      "In development mode, specify the user's actual token: set localStorage auth_token or /?set-auth-token={token}."
    )
  }
}

export const handleProductionMode = async () => {
  try {
    const { token } = await api.auth.telegram({
      initData: window.Telegram.WebApp.initData,
    })

    localStorage.setItem('auth_token', token)
    const user = await api.users.me()
    return { user }
  } catch {
    throw new Error('Authorization failed')
  }
}

export const handleMockUser = (mockUserType: string | null) => {
  let user: User

  switch (mockUserType) {
    case 'memer':
      user = mockMemer as unknown as User
      break
    case 'owner':
      user = mockOwner as unknown as User
      break
    default:
      user = mockUser as unknown as User
  }

  return { user }
}
