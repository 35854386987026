<template>
  <PopupBottom class="popup-new-squad" @close="$emit('close')">
    <MainHeader class="popup-new-squad__main-header" />
    <div class="popup-new-squad__content">
      <div class="popup-new-squad__icon"></div>
      <div class="popup-new-squad__head">
        {{ $t('popupNewSquad.head') }}
      </div>
      <div class="popup-new-squad__text">
        {{ $t('popupNewSquad.text', { value: constants.squadMaxFee }) }}
      </div>
      <InputBlock
        v-model="inputValue"
        class="popup-new-squad__input-block"
        :label="$t('popupNewSquad.inputLabel')"
        placeholder="t.me/"
        :errorText="errorTextInput"
        @input="resetError"
      />
      <div class="popup-new-squad__button-container">
        <ButtonAction
          class="popup-new-squad__button"
          size="big"
          color="green"
          icon="energy-green"
          :loading="loading"
          @click="createNewSquad"
        >
          {{
            $t('popupNewSquad.buttonText', {
              price: constants.createSquadPrice,
            })
          }}
        </ButtonAction>
      </div>
      <ErrorText v-if="!!errorTextServer" class="popup-new-squad__error-text">{{
        errorTextServer
      }}</ErrorText>
      <HeadLined class="popup-new-squad__head-lined">
        {{ $t('popupNewSquad.rulesTitle') }}
      </HeadLined>
      <div class="popup-new-squad__rules-list">
        <div
          v-if="user.coins > 0"
          class="popup-new-squad__rules-item"
          v-html="
            $t('popupNewSquad.rulesList.coinsLost', {
              value: formatNumber(user.coins),
              icon: coinsIconTemplate,
            })
          "
        />
        <div
          v-if="user.energy > constants.createSquadPrice"
          class="popup-new-squad__rules-item"
          v-html="
            $tc(
              'popupNewSquad.rulesList.energyLost',
              user.energy - constants.createSquadPrice,
              {
                value: formatNumber(user.energy - constants.createSquadPrice),
                icon: energyIconTemplate,
              }
            )
          "
        />
        <div
          class="popup-new-squad__rules-item"
          v-if="user.refCount > 0"
          v-html="
            $tc('popupNewSquad.rulesList.friendsInvited', user.refCount, {
              value: user.refCount,
              icon: peopleIconTemplate,
            })
          "
        />
        <div
          class="popup-new-squad__rules-item"
          v-html="$t('popupNewSquad.rulesList.cantFarm')"
        />
      </div>
    </div>
  </PopupBottom>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PopupBottom from '@/components/PopupBottom.vue'
import ButtonAction from '@/components/ButtonAction.vue'
import HeadLined from '@/components/HeadLined.vue'
import InputBlock from '@/components/InputBlock.vue'
import ErrorText from './ErrorText.vue'
import MainHeader from './MainHeader.vue'
import {
  coinsIconTemplate,
  energyIconTemplate,
  peopleIconTemplate,
} from '@/lib/iconTemplates'
import api from '@/api'
import { formatNumber } from '@/lib/utils'

export default {
  name: 'PopupNewSquad',
  components: {
    ButtonAction,
    PopupBottom,
    HeadLined,
    InputBlock,
    ErrorText,
    MainHeader,
  },
  emits: ['close', 'squad-created'],
  data: () => ({
    energyIconTemplate,
    coinsIconTemplate,
    peopleIconTemplate,
    inputValue: '',
    errorTextInput: '',
    errorTextServer: '',
    loading: false,
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('constants', ['constants']),
    formattedLink() {
      let input = this.inputValue.trim()
      input = input.replace(/^(http:\/\/|https:\/\/)?(t\.me\/)?/i, '')

      return `https://t.me/${input}`
    },
  },
  methods: {
    formatNumber,
    ...mapActions('squadsTop', ['fetchActiveTop']),
    validateInput() {
      if (this.user.energy < this.constants.createSquadPrice) {
        this.errorTextServer = this.$t('popupNewSquad.errors.noEnergy')
        return false
      }

      if (!this.inputValue) {
        this.errorTextInput = this.$t('popupNewSquad.errors.invalidUrl')
        return false
      }

      const validStarts = ['t.me', 'http://t.me', 'https://t.me']
      if (
        !validStarts.some((prefix) =>
          this.inputValue.toLowerCase().startsWith(prefix)
        )
      ) {
        if (this.inputValue.includes('/t.me')) {
          this.errorTextInput = this.$t('popupNewSquad.errors.invalidUrl')
          return false
        }
        this.errorTextInput = this.$t('popupNewSquad.errors.invalidDomain')
        return false
      }

      if (this.formattedLink.length < 14) {
        this.errorTextInput = this.$t('popupNewSquad.errors.invalidUrl')
        return false
      }

      return true
    },
    resetError() {
      this.errorTextInput = ''
      this.errorTextServer = ''
    },
    async createNewSquad() {
      if (!this.validateInput()) {
        return
      }
      try {
        this.loading = true
        await api.squads.createSquad(this.formattedLink)
        this.$store.dispatch('eventManager/trackEvent', {
          eventType: 'squadCreated',
        })
        await this.$store.dispatch('user/fetchUser')
        await this.fetchActiveTop()
        this.$emit('squad-created')
      } catch (error) {
        if (error?.response?.data?.message === 'EXISTS') {
          this.errorTextInput = this.$t('popupNewSquad.errors.exists')
        } else {
          this.errorTextInput = this.$t('popupNewSquad.errors.invalidUrl')
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.popup-new-squad {
  .popup-bottom__popup {
    position: fixed;
    top: 0rem;
    left: 0;
    width: 100%;
    bottom: 50vh;
    height: var(--tg-viewport-stable-height);
    box-shadow: 0 100vh 0 100vh $color-violet-dark;
    border-radius: 0;
  }

  .popup-bottom__close {
    top: 55rem;
  }

  &__main-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    .main-header__plate_type_invites::before {
      background-color: rgba($color-cyan, 0.2);
    }

    .main-header__plate_type_coins::before {
      background-color: rgba($color-yellow, 0.2);
    }

    .main-header__plate_type_energy::before {
      background-color: rgba($color-green, 0.2);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    background-image: url(@/assets/icons/people-3.svg);
    background-position: center;
    background-size: contain;
    width: 114rem;
    height: 76rem;
    margin-bottom: 16rem;
    filter: drop-shadow(0 0 5rem rgba($color-cyan, 0.4));
  }

  &__head {
    font-size: 24rem;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 8rem;
    text-transform: uppercase;
  }

  &__text {
    font-size: 16rem;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 16rem;
    text-align: center;
  }

  &__head-lined {
    margin-bottom: 16rem;
    margin-top: 16rem;
    width: 100%;
  }

  &__rules-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15rem;
    margin-bottom: 16rem;
  }

  &__rules-item {
    font-size: 16rem;
    position: relative;
    padding-left: 0.6em;

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0.6em;
      left: 0;
      width: 4rem;
      height: 4rem;
      border-radius: 2rem;
      background: $color-cyan;
    }
  }

  &__button-container {
    display: flex;
    gap: 8rem;
    width: 100%;
    margin-top: 16rem;
  }

  &__input-block {
    .input-block__label {
      span {
        color: white;
      }
    }
  }

  &__button {
    flex: 1;
  }

  &__error-text {
    width: 100%;
    margin-top: 0.4em;
  }
}
</style>
