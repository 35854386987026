<template>
  <div class="squad-fee-range">
    <HeadLined class="squad-fee-range__head">
      {{ $t('squadFeeRange.title') }}
    </HeadLined>
    <div class="squad-fee-range__header">
      <span class="squad-fee-range__percentage">{{ formattedValue }}%</span>
    </div>
    <RangeSlider
      v-model="localValue"
      :min="min"
      :max="max"
      :loading="loading"
      @mouse-up="$emit('mouse-up')"
    />
    <div class="squad-fee-range__footer">
      <div class="squad-fee-range__footer-side">
        <span class="squad-fee-range__min">{{ min }}%</span>
      </div>
      <div class="squad-fee-range__footer-side">
        <span class="squad-fee-range__max">{{ max }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumber } from '@/lib/utils'
import HeadLined from './HeadLined.vue'
import RangeSlider from './RangeSlider.vue'

export default {
  name: 'SquadFeeRange',
  emits: ['mouse-up', 'update:modelValue'],
  components: {
    RangeSlider,
    HeadLined,
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 3,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit('update:modelValue', parseFloat(newValue))
    },
    modelValue(newValue) {
      this.localValue = newValue
    },
  },
  data() {
    return {
      localValue: this.modelValue,
    }
  },
  methods: {
    formatNumber,
  },
  computed: {
    formattedValue() {
      return this.localValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.squad-fee-range {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 10px 10px;

  &__title {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  &__title-text {
  }

  &__head {
    width: 100%;
    margin-bottom: 10rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10rem;
    width: 100%;
    font-size: 40rem;
    margin-bottom: 5rem;
    line-height: 1em;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16rem;
  }

  &__footer-side {
    display: flex;
    align-items: center;
    gap: 0.3em;
    font-size: 16rem;
  }

  &__icon {
    width: 20rem;
    height: 20rem;
    margin-right: 5rem;
    position: relative;
    flex-shrink: 0;
    filter: drop-shadow(0 0 7rem rgba($color-yellow, 0.4));
  }

  &__percentage {
    font-size: 40rem;
    font-weight: bold;
    color: white;
  }

  &__min,
  &__max {
    color: white;
    font-size: 16rem;
  }

  &__description {
    text-align: center;
    font-size: 16em;
    opacity: 0.3;
    white-space: pre-wrap;
  }
}
</style>
