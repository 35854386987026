<template>
  <PopupBottom class="popup-join-squad" no-close @close="$emit('close')">
    <div class="popup-join-squad__content">
      <div class="popup-join-squad__head">
        {{ $t('popupJoinSquad.head') }}
      </div>

      <SquadItem :squad="squad" is-preview />

      <ButtonAction
        class="popup-join-squad__button"
        size="big"
        color="green"
        @click="$emit('close')"
      >
        {{ $t('popupJoinSquad.ok') }}
      </ButtonAction>
    </div>
  </PopupBottom>
</template>

<script>
import PopupBottom from '@/components/PopupBottom.vue'
import ButtonAction from '@/components/ButtonAction.vue'
import SquadItem from '@/components/SquadItem.vue'

export default {
  name: 'PopupJoinSquad',
  components: { SquadItem, ButtonAction, PopupBottom },
  emits: ['close'],
  props: {
    squad: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.popup-join-squad {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__head {
    margin-bottom: 20rem;
    font-size: 24rem;
    font-weight: 600;
    line-height: 1.4;
  }

  &__button {
    margin-top: 24rem;
    width: 100%;
  }
}
</style>
