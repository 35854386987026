<template>
  <div class="app">
    <div class="app__background" />

    <AppDevice v-if="shouldShowDevice" class="app__app-device" />

    <AppLoader
      v-if="
        isMainLoadingShow && $store.getters['errors/allErrors'].length === 0
      "
      :loaded="isMainLoadingFinished"
      @after-loading-finished="setMainLoadingShow(false)"
      class="app__app-loader"
    />

    <router-view
      v-else-if="isMobile"
      v-show="!shouldShowDevice"
      class="app__page"
    />

    <SoundEffects />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppLoader from './components/AppLoader.vue'
import AppDevice from './components/AppDevice.vue'
import WebSocketService from '@/services/WebSocketService'
import SoundEffects from './components/SoundEffects.vue'

export default {
  name: 'App',
  components: { AppLoader, AppDevice, SoundEffects },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('device', {
      isLandscape: (state) => state.isLandscape,
      isMobile: (state) => state.isMobile,
      isConnectionClosed: (state) => state.isConnectionClosed,
    }),
    ...mapState({
      isMainLoadingShow: (state) => state.isMainLoadingShow,
      isMainLoadingFinished: (state) => state.isMainLoadingFinished,
    }),
    shouldShowDevice() {
      return this.isLandscape || !this.isMobile || this.isConnectionClosed
    },
  },
  watch: {
    user: {
      handler(value) {
        if (value) this.setMainLoadingFinished(true)
      },
      immediate: true,
    },
  },
  methods: {
    setMainLoadingShow(value) {
      this.$store.commit('setMainLoadingShow', value)
    },
    setMainLoadingFinished(value) {
      this.$store.commit('setMainLoadingFinished', value)
    },
  },
  async created() {
    try {
      await this.$store.dispatch('initApp')
    } catch (error) {
      this.$router.push({ name: 'error' })
    }

    if (this.isMobile) {
      await WebSocketService.connect()
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/base64-images.scss';
@import '@/styles/helpers.scss';

.app {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;

  &__page {
    position: relative;
    z-index: 20;
    flex-grow: 1;
  }

  &__background {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--tg-viewport-stable-height);
    background: center center no-repeat url(@/assets/images/background.jpg),
      center center no-repeat url($image-background);
    background-size: cover;
  }

  &__app-loader {
    margin-top: 40vh;
    margin: 60vh auto 0;
    z-index: 20;
  }

  &__app-device {
    top: 0;
    right: 0;
    left: 0;
    height: var(--tg-viewport-stable-height);
    position: fixed;
    z-index: 30;
  }
}
</style>
