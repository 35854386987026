<template>
  <header class="main-header">
    <div :class="bem('main-header__plates', { owner: isSquadOwner })">
      <div :class="bem('main-header__plate', { type: 'invites' })">
        <img
          v-if="isSquadOwner"
          :class="bem('main-header__plate-icon', { type: 'people3' })"
          src="@/assets/icons/people-3.svg"
          alt="People"
        />
        <img
          v-else
          :class="bem('main-header__plate-icon', { type: 'people' })"
          src="@/assets/icons/people.svg"
          alt="People"
        />
        <div class="main-header__plate-value">
          {{
            formatNumber(
              (isSquadOwner ? user.membersCount : user.invitedFriendsCount) || 0
            )
          }}
        </div>
      </div>
      <div :class="bem('main-header__plate', { type: 'coins' })">
        <img
          :class="bem('main-header__plate-icon', { type: 'coins' })"
          src="@/assets/icons/coins-yellow.png"
          alt="People"
        />
        <div class="main-header__plate-value">
          {{ formatNumber(user.coins) }}
        </div>
      </div>
      <div
        v-if="!isSquadOwner"
        :class="bem('main-header__plate', { type: 'energy', last: true })"
      >
        <img
          :class="bem('main-header__plate-icon', { type: 'energy' })"
          class="main-header__plate-icon"
          src="@/assets/icons/energy-green.svg"
          alt="People"
        />
        <div class="main-header__plate-value">
          {{ formatNumber(user.energy) }}
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { formatNumber } from '@/lib/utils'

export default {
  name: 'MainHeader',
  methods: {
    formatNumber,
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isSquadOwner']),
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.main-header {
  overflow: hidden;
  --plate-height: var(--header-height);

  &__plate {
    position: relative;
    display: flex;
    align-items: center;
    height: var(--plate-height);
    padding: 0 8rem 2rem;

    &::before {
      content: '';
      width: calc(100% + 18rem);
      height: 100%;
      position: absolute;
      top: 0;
      left: -5rem;
      display: block;
      border-top: 0;
      border-right: 3px solid;
      border-bottom: 2px solid;
      border-left: 0;
      transform: skewX(-45deg);
    }

    &:last-child {
      &:before {
        width: calc(100% + 30rem);
      }
    }

    &:first-child {
      &:before {
        left: -15rem;
        width: calc(100% + 30rem);
      }
    }

    &_type {
      &_invites {
        &::before {
          border-color: $color-cyan;
          background-color: $color-cyan-black;
        }
      }

      &_coins {
        &::before {
          border-color: $color-yellow;
          background-color: $color-yellow-black;
        }
      }

      &_energy {
        &::before {
          border-color: $color-green;
          background-color: $color-green-black;
        }
      }
    }
  }

  &__plates {
    display: flex;
    justify-content: space-between;

    &_owner {
      flex-direction: row-reverse;

      .main-header__plate {
        // last-child (row-reverse)
        &:first-child {
          &:before {
            left: -5rem;
            width: calc(100% + 30rem);
          }
        }

        // first-child (row-reverse)
        &:last-child {
          &:before {
            left: -15rem;
            width: calc(100% + 30rem);
          }
        }
      }
    }
  }

  &__plate-icon {
    width: 20rem;

    &_type {
      &_people3 {
        width: 27rem;
        filter: drop-shadow(0 0 3rem rgba($color-cyan, 0.3));
      }
      &_people {
        width: 21rem;
        filter: drop-shadow(0 0 3rem rgba($color-cyan, 0.3));
      }
      &_coins {
        filter: drop-shadow(0 0 3rem rgba($color-yellow, 0.3));
      }
      &_energy {
        filter: drop-shadow(0 0 3rem rgba($color-green, 0.3));
      }
    }
  }

  &__plate-value {
    margin-left: 6rem;
    font-size: 16rem;
    font-weight: bold;
    color: white;
    z-index: 1;
    position: relative;
  }
}
</style>
