import axios from 'axios'

export async function preloadAssets() {
  const { data } = await axios.get('/assets-manifest.json')
  const promises = []

  for (const url of Object.values(data) as string[]) {
    const img = new Image()

    promises.push(
      new Promise((resolve) => {
        img.onload = resolve
        img.onerror = resolve
        img.onabort = resolve
      })
    )

    img.src = url
  }

  return Promise.all(promises)
}
