<template>
  <button class="button-swither" @click="cycleValue">
    <span class="button-swither__title">
      {{ currentItem.title }}
    </span>
    <img
      src="@/assets/icons/switch.svg"
      class="button-swither__icon"
      alt="switch-icon"
    />
  </button>
</template>

<script>
export default {
  name: 'SwitchButton',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentItem() {
      return (
        this.items.find((item) => item.id === this.modelValue) || this.items[0]
      )
    },
  },
  methods: {
    cycleValue() {
      const currentIndex = this.items.findIndex(
        (item) => item.id === this.modelValue
      )
      const nextIndex = (currentIndex + 1) % this.items.length
      this.$emit('update:modelValue', this.items[nextIndex].id)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.button-swither {
  display: flex;
  align-content: center;
  justify-content: center;
  border: initial;
  background-color: $color-cyan-dark;
  color: white;
  border-radius: 4rem;
  gap: 4rem;
  padding: 5rem 10rem;
  transition: 0.1s transform;

  &:active {
    transform: scale(0.95);
  }

  &__title {
    font-size: 18rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__icon {
    height: 20rem;
  }
}
</style>
