export const mockUser = {
  id: 3,
  username: 'johndoe',
  energy: 10,
  coins: 0,
  hasSubscriptionReward: false,
  invitedFriendsCount: 0,
  isSquadOwner: false,
  membershipSquadId: null,
  collectEnergyRemainingSeconds: null,
}

export const mockMemer = {
  ...mockUser,
  currentGroup: {
    _id: '66643895306935e095fd8e78',
    name: 'Squad name',
    link: 'https://t.me/testhgroup123выауа23к',
    members: ['66634ab627c12879f046a20d', '66634ab627c12879f046a20d'],
    owner: '',
    avatar: '',
    commission: 1.19,
    coins: 123,
    membersCount: 9234,
    membersStats: 0,
  },
}

export const mockOwner = {
  ...mockMemer,
  currentGroup: {
    ...mockMemer.currentGroup,
    owner: '66634ab627c12879f046a20d',
  },
}
