import api from '@/api'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import store from '@/store'

const state = {
  user: null as User | null,
  lastFetched: null as number | null, // Для хранения времени получения пользователя
}

const getters: GetterTree<typeof state, RootState> = {
  isMember(state): boolean {
    return !!state.user?.owningSquad || !!state.user?.membershipSquad
  },

  isSquadOwner(state): boolean {
    return !!state?.user?.isSquadOwner
  },
  getTopPosition:
    (state) =>
    (topList: Squad[]): number | string | null => {
      if (!topList || !state.user) return null
      const index = topList.findIndex(
        (squad: Squad) =>
          squad.id === state.user?.owningSquad?.id ||
          squad.id === state.user?.membershipSquad?.id
      )
      return index !== -1 ? index + 1 : '100+'
    },

  weeklyTopPosition(state, getters, rootState): number | string | null {
    const { weeklyTop } = rootState.squadsTop
    return getters.getTopPosition(weeklyTop)
  },

  dailyTopPosition(state, getters, rootState): number | string | null {
    const { dailyTop } = rootState.squadsTop
    return getters.getTopPosition(dailyTop)
  },
  getRemainingEnergyTime(state) {
    return () => {
      if (!state.user || state.lastFetched === null) {
        return 0
      }
      const elapsed = Math.floor((Date.now() - state.lastFetched) / 1000)
      return Math.max(state.user.collectEnergyRemainingSeconds - elapsed, 0)
    }
  },
}

const mutations: MutationTree<typeof state> = {
  setUser(state, { user }: { user: User }) {
    state.user = user
    state.lastFetched = Date.now()
    store.commit('notifications/setNotifications', user.notifications)
  },
  setUserSubscriptionReward(state, value: boolean) {
    if (state.user) {
      state.user.hasSubscriptionReward = value
    }
  },
  setUserEnergy(state, value: number) {
    if (state.user) {
      state.user.energy = value
    }
  },
  setUserCoins(state, value: number) {
    if (state.user) {
      state.user.coins = value
    }
  },
  setUserInvitedFriendsCount(state, value: number) {
    if (state.user) {
      state.user.invitedFriendsCount = value
    }
  },
  setUserMembersCount(state, value: number) {
    if (state.user) {
      state.user.membersCount = value
    }
  },
  setUserMembershipSquad(state, squad: Squad) {
    if (state.user) {
      state.user.membershipSquad = squad
    }
  },
  setUserOwningSquad(state, squad: Squad) {
    if (state.user) {
      state.user.owningSquad = squad
    }
  },
}

const actions: ActionTree<typeof state, RootState> = {
  async fetchUser({ commit }) {
    const user = await api.users.me()
    commit('setUser', { user })
  },
}

const user = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default user
