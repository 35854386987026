<template>
  <div class="squad-item">
    <a class="squad-item__name" :href="squad.link" @click="openSquadLink">
      <img
        v-if="squad.avatarUrl"
        class="squad-item__avatar"
        :src="squad.avatarUrl"
      />
      <img
        v-else
        class="squad-item__avatar"
        src="@/assets/images/squad-avatar-placeholder.jpg"
      />
    </a>
    <div class="squad-item__content">
      <div class="squad-item__head-line">
        <a class="squad-item__name" :href="squad.link" @click="openSquadLink">
          {{ squad.name }}
        </a>
      </div>

      <div class="squad-item__content-line">
        <div
          class="squad-item__content-item"
          v-if="positionInTop || getSquadPosition"
        >
          <div class="squad-item__content-dot">•</div>
          <div class="squad-item__rating">
            {{ $t('squadItem.place') }}:
            <SpinnerBlock class="squad-item__spinner-block" v-if="loading" />
            <span v-else>{{
              positionInTop || getSquadPosition(squad.id)
            }}</span>
          </div>
        </div>
        <div
          class="squad-item__content-item"
          v-if="!hideItems.includes('coins')"
        >
          <div class="squad-item__content-dot">•</div>
          <img
            class="squad-item__coins-icon"
            src="@/assets/icons/coins-yellow.png"
          />
          <div class="squad-item__coins-text">
            {{
              formatNumber(
                activeType === 'weekly' ? squad.weeklyCoins : squad.dailyCoins
              )
            }}
          </div>
        </div>

        <div class="squad-item__content-item" v-if="!hideItems.includes('fee')">
          <div class="squad-item__coins-text">
            <div class="squad-item__content-dot">•</div>
            <span
              >{{ $t('squadItem.commission') }}:
              {{ formatNumber(squad.fee) }}%</span
            >
          </div>
        </div>

        <div
          class="squad-item__content-item"
          v-if="!isPreview && squad.membersStats"
        >
          <div class="squad-item__content-dot">•</div>
          <div class="squad-item__people">
            <img
              class="squad-item__people-icon"
              src="@/assets/icons/people-3.svg"
            />
            <div class="squad-item__people-text">
              {{ squad.membersStats }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="squad-item__actions" v-if="canJoin || canLeave">
      <ButtonAction
        v-if="canJoin"
        class="squad-item__button-join"
        color="green"
        :loading="joinLoaing"
        @click="joinToSquad"
      >
        <span class="squad-item__button-text">{{
          $t('squadItem.buttonJoin')
        }}</span>
      </ButtonAction>
      <ButtonAction
        v-else-if="canLeave"
        color="pink"
        @click="popupLeaveOpened = true"
      >
        <div class="squad-item__button-text">
          {{ $t('squadItem.buttonLeave') }}
        </div>
      </ButtonAction>
    </div>

    <Teleport v-if="!isPreview" to="body">
      <PopupJoinSquad
        v-if="popupJoinOpened"
        :squad="squad"
        @close="popupJoinOpened = false"
      />
      <PopupLeaveSquad
        v-if="popupLeaveOpened"
        :squad="squad"
        @close="popupLeaveOpened = false"
        @leave="popupLeaveOpened = false"
      />
    </Teleport>
  </div>
</template>

<script>
import { getSquadLevelTitle } from '@/lib/squadLevels'
import { formatNumber } from '@/lib/utils'
import ButtonAction from '@/components/ButtonAction.vue'
import PopupLeaveSquad from '@/components/PopupLeaveSquad.vue'
import PopupJoinSquad from '@/components/PopupJoinSquad.vue'
import api from '@/api'
import { mapGetters, mapState } from 'vuex'
import SpinnerBlock from './SpinnerBlock.vue'

export default {
  name: 'SquadItem',
  components: { PopupLeaveSquad, ButtonAction, PopupJoinSquad, SpinnerBlock },

  props: {
    squad: {
      type: Object,
      required: true,
    },
    canJoin: {
      type: Boolean,
      default: false,
    },
    canLeave: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    hideItems: {
      type: Array,
      default: () => [],
    },
    positionInTop: {
      type: [Number, String],
      default: null,
    },
    coins: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    popupLeaveOpened: false,
    popupJoinOpened: false,
    joinLoaing: false,
  }),
  computed: {
    levelTitle() {
      return getSquadLevelTitle(this.squad.level)
    },
    ...mapGetters('user', ['isSquadOwner']),
    ...mapState('squadsTop', ['activeType', 'loading']),
    ...mapGetters('squadsTop', ['getSquadPosition']),
  },
  methods: {
    formatNumber,
    async joinToSquad() {
      this.joinLoaing = true
      await api.squads.joinSquad(this.squad.id)
      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'joinedSquad',
        eventProperties: {
          type: 'direct',
        },
      })
      await this.$store.dispatch('user/fetchUser')
      this.joinLoaing = false
      this.popupJoinOpened = true
    },
    openSquadLink() {
      if (this.isSquadOwner) {
        this.$store.dispatch('eventManager/trackEvent', {
          eventType: 'squadOwner_squadClicked',
        })
      } else {
        this.$store.dispatch('eventManager/trackEvent', {
          eventType: 'squadClicked',
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.squad-item {
  display: flex;
  align-items: center;
  padding: 15rem 0;

  &__avatar {
    flex-shrink: 0;
    width: 52rem;
    height: 52rem;
    margin-right: 12rem;
    border-radius: 8rem;
    object-fit: cover;
  }

  &__avatar-placeholder {
    flex-shrink: 0;
    width: 52rem;
    height: 52rem;
    margin-right: 12rem;
    border-radius: 8rem;
    background-color: $color-pink;
    background-image: var(--avatar);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }

  &__name {
    font-size: 16rem;
    font-weight: 500;
    line-height: 1.25;
    text-decoration: underline;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  &__actions {
    margin-left: 10rem;
  }

  &__head-line {
    display: flex;
    align-items: flex-end;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  &__content-line {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1em;
  }

  &__content-item {
    display: flex;
    align-items: center;
  }

  &__spinner-block {
    font-size: 8rem;
  }

  &__content-dot {
    margin: 0 4rem;
    font-size: 24rem;
    line-height: 14rem;
  }

  &__rating {
    font-size: 16rem;
    line-height: 1.25;
    color: $color-cyan;
  }

  &__coins {
    display: flex;
    align-items: center;
  }

  &__coins-icon {
    margin-right: 4rem;
    width: 16rem;
  }

  &__coins-text {
    font-size: 16rem;
    line-height: 1.5;
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
  }

  &__people {
    display: flex;
    align-items: center;
  }

  &__people-icon {
    margin-right: 2rem;
    width: 24rem;
  }

  &__people-text {
    font-size: 16rem;
    font-weight: bold;
    line-height: 1.5;
  }

  &__button-join {
    min-width: 80rem;
  }

  &__button-text {
    padding: 0 10rem;
  }
}
</style>
