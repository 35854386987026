import { createStore, GetterTree, MutationTree, ActionTree } from 'vuex'
import { share } from '@/lib/telegram'
import auth from '@/lib/auth'
import { preloadAssets } from '@/lib/preloadAssets'

import device from './modules/device'
import constants from './modules/constants'
import user from './modules/user'
import notifications from './modules/notifications'
import squadsTop from './modules/squadsTop'
import errors from './modules/errors'
import eventManager from './modules/eventManager'

import * as Sentry from '@sentry/vue'

const state: RootState = {
  telegramId: null,
  boxGameOpened: false,
  isMainLoadingShow: true,
  isMainLoadingFinished: false,
  joinChannelLoading: false,
}

const getters: GetterTree<RootState, RootState> = {
  refererLink(state): string {
    return `https://t.me/${process.env.VUE_APP_BOT_NICKNAME}/start?startapp=${state.telegramId}`
  },
  energyLootboxLink(state): string {
    return `${process.env.VUE_APP_FARM_LOOTBOX_LINK}/#${btoa(
      localStorage.getItem('auth_token') || ''
    )}`
  },
}

const mutations: MutationTree<RootState> = {
  openBoxGame(state) {
    state.boxGameOpened = true
  },
  closeBoxGame(state) {
    state.boxGameOpened = false
  },
  setTelegramId(state, id: string) {
    state.telegramId = id
  },
  setMainLoadingShow(state, value: boolean) {
    state.isMainLoadingShow = value
  },
  setMainLoadingFinished(state, value: boolean) {
    state.isMainLoadingFinished = value
  },
  setJoinChannelLoading(state, value: boolean) {
    state.joinChannelLoading = value
  },
}

const actions: ActionTree<RootState, RootState> = {
  inviteFriend({ getters }) {
    share(getters.refererLink)
  },
  joinChannel() {
    setTimeout(() => {
      window.open(process.env.VUE_APP_BOT_JOIN_CHANNEL_LINK)
    })
  },
  async initApp({ commit, dispatch }) {
    commit('setMainLoadingShow', true)
    preloadAssets()

    await dispatch('device/init', { root: true })

    window.Telegram.WebApp.ready()
    window.Telegram.WebApp.expand()
    window.Telegram.WebApp.enableClosingConfirmation()

    if (window.Telegram.WebApp.initDataUnsafe.user) {
      commit('setTelegramId', window.Telegram.WebApp.initDataUnsafe.user.id)
      Sentry.setUser({
        username: `${window.Telegram.WebApp.initDataUnsafe.user.id}`,
      })
    }

    const { user } = await auth.authorize()

    commit('user/setUser', { user }, { root: true })

    dispatch('eventManager/initEventManager', user.id)

    Sentry.setUser({
      id: user.id,
      username: `${window?.Telegram?.WebApp?.initDataUnsafe?.user?.id}`,
    })

    if (user.isSquadOwner) {
      dispatch('eventManager/trackEvent', {
        eventType: 'squadOwner_gameStarted',
      })
    } else {
      dispatch('eventManager/trackEvent', { eventType: 'gameStarted' })
    }

    commit('setMainLoadingFinished', true)
  },
}

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    device,
    constants,
    user,
    notifications,
    squadsTop,
    errors,
    eventManager,
  },
})
