import scaleNoEnergy from '@/assets/images/box-game-scale-no-energy-en.png'
import textNoEnergy from '@/assets/images/text-no-energy-en.png'
import collectYourReward from '@/assets/images/text-collect-your-reward-en.png'
import tap from '@/assets/images/text-tap-en.png'
import normal from '@/assets/images/text-normal-1-en.png'
import good from '@/assets/images/text-good-2-en.png'
import perfect from '@/assets/images/text-perfect-4-en.png'

export default {
  tools: {
    squadLevels: {
      bronze: 'Bronze',
      silver: 'Silver',
      gold: 'Gold',
      diamond: 'Diamond',
    },
    rotateMessage: 'Please rotate the device',
    disconnectedMessage: 'You have started the game on another device',
    playOnMobile: 'Play on your mobile for more fun!',
    countdown: {
      d: 'D',
    },
  },
  pageMain: {},
  gameContent: {
    tabFarm: 'Farm',
    tabTop: 'Rankings',
    tabMySquad: 'My squad',
    yourSquadHead: 'Your squad',
  },
  tasksList: {
    joinChannel: {
      title: 'Join to Hyper to get early access',
      buttonText: 'Join',
    },
    invite: {
      title: 'Invite friends',
      buttonText: 'Invite',
      terms: 'terms',
    },
    farmEnergy: {
      title: 'Farm energy without limits',
    },
    waitSquadEnergy: {
      title: 'Recharging Energy +{ value } { icon }',
    },
    getSquadEnergy: {
      title: 'Energy recharged',
      buttonText: 'Claim',
    },
  },
  squadsList: {
    yourSquadHead: 'Your squad',
    listHead: 'Top squads',
    competition: 'Competition',
  },
  squadsEmpty: {
    text: 'No one has created squads yet. Be the first to create one',
    buttonText: 'Create squad',
  },
  squadItem: {
    buttonJoin: 'Join',
    buttonLeave: 'Leave',
    commission: 'fee',
    place: 'rank',
  },
  popupSquad: {
    joinSquad: 'Join squad',
  },
  popupNewSquad: {
    head: 'Сreate your squad',
    text: 'Earn up to {value}% from all coins farmed by squad members',
    rulesTitle: 'Rules',
    rulesList: {
      coinsLost: '{icon} <b>{value}</b> will remain on the balance',
      energyLost: '{icon} <b>{value}</b> will be lost',
      friendsInvited: ({ plural, named }: PluralNamed) =>
        plural([
          `${named('icon')} <b>${named('value')}</b>
          fiend will receive an invitation to join your squad`,
          `${named('icon')} <b>${named('value')}</b>
          friends will receive an invitation to join your squad`,
        ]),
      cantFarm: 'You will no longer be able to farm lootboxes',
    },
    createTitle: 'Create squad',
    createRequirement:
      'Add <span class="highlited">{botNickname}</span> to your Telegram channel/chat before creating the squad',
    inputLabel: 'Link to <span>your(!)</span> Telegram channel/chat',
    buttonText: 'Create squad for {price}',
    errors: {
      invalidUrl: 'Please check the provided URL',
      invalidDomain: 'The link must start with t.me',
      noEnergy: 'Not enough energy to create the squad',
      exists: 'A squad already exists',
    },
  },
  popupSquadInvite: {
    head: 'Invitation to join the squad',
    join: 'Join',
    decline: 'Decline',
  },
  popupJoinSquad: {
    head: 'You have joined the squad',
    ok: 'Ok',
  },
  popupLeaveSquad: {
    head: 'Do you want to leave the squad?',
    in: 'in',
    text: 'Leaving the squad will reset the energy recovery timer',
    stay: 'Stay',
    leave: 'Leave',
  },
  popupChangeSquad: {
    head: 'Offer to change\nthe squad',
    squadFromHead: 'Your current squad',
    squadToHead: 'New squad',
    buttonChange: 'Change squad',
    buttonDecline: 'Decline',
  },
  popupInviteTerms: {
    head: 'Reward for inviting a friend',
    text: 'You will get a reward when your friend farms {userThreshold} hypercoins',
    ok: 'Ok',
  },
  popupGetReward: {
    head: 'Claim your reward',
    rewards: {
      friends: ({ plural, named }: PluralNamed) =>
        plural([
          `Your reward for inviting ${named('value')} friend`,
          `Your reward for inviting ${named('value')} friends`,
        ]),
      telegramSubscribed:
        'You have successfully subscribed to the Telegram channel',
    },
    ok: 'Claim',
  },
  boxPreview: {
    buttonText: 'Smash lootbox',
  },
  boxGameScene: {
    normal: normal,
    good: good,
    perfect: perfect,
    tap: tap,
    scaleNoEnergy: scaleNoEnergy,
    textNoEnergy: textNoEnergy,
    buttonInviteText: 'Invite friend',
    buttonFarmEnergyText: 'Farm energy',
    rewardHead: collectYourReward,
    buttonCollectReward: 'Collect',
  },
  noSquadInfo: {
    buttonCreateSquad: 'Create your squad',
    or: 'or',
    joinSquadText:
      'Join a squad and get <span style="white-space: nowrap"><b>{reward}</b> {icon}</span> every <b>8</b> hours!',
  },
  mySquadManager: {
    head: 'Statstics',
    inviteButton: ' Invite to Squad',
  },
  squadStats: {
    income: 'Income',
    members: 'Members squads',
  },
  squadFeeRange: {
    title: 'SQUAD FEE',
    description:
      "The lower the squad's commission,\nthe higher the squad ranks",
  },
  sort: {
    today: 'Today',
    week: 'Week',
  },
  errorPage: {
    title: 'An error has occurred',
    text: 'Unfortunately, an error has occurred. Please try reloading the application or come back later.',
    buttonReloadText: 'Reload',
    buttonSupprotText: 'Write to support',
  },
}
