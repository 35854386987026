<template>
  <div class="tasks-list">
    <div class="tasks-list__task" v-if="!user.hasSubscriptionReward">
      <div :class="bem('tasks-list__icon', { type: 'telegram' })" />

      <div
        class="tasks-list__title"
        v-html="$t('tasksList.joinChannel.title')"
      />

      <ButtonAction
        :class="
          bem('tasks-list__button', { 'join-loading': joinChannelLoading })
        "
        icon="energy-green"
        color="cyan"
        :loading="joinChannelLoading"
        @click="joinChannelHandler"
      >
        {{ joinChannelButtonText }}
      </ButtonAction>
    </div>

    <div class="tasks-list__task">
      <div :class="bem('tasks-list__icon', { type: 'people' })" />

      <div class="tasks-list__title">
        <span v-html="$t('tasksList.invite.title')" />
        <br />
        <span class="tasks-list__title-extra" @click="termsOpened = true">
          • {{ $t('tasksList.invite.terms') }}
        </span>
      </div>

      <ButtonAction
        class="tasks-list__button"
        icon="coins"
        color="cyan"
        @click="shareLink"
      >
        {{ inviteFriendButtonText }}
      </ButtonAction>
    </div>
    <div v-if="!isMember" class="tasks-list__task">
      <div :class="bem('tasks-list__icon', { type: 'energy' })" />
      <div
        class="tasks-list__title"
        v-html="
          $t('tasksList.farmEnergy.title', {
            icon: energyIconTemplate,
            reward: constants.squadMembershipReward,
          })
        "
      />

      <div class="tasks-list__button-join-squad" @click="goToFarmEnegry" />
    </div>

    <template v-else>
      <div v-if="getRemainingEnergyTime() <= 0" class="tasks-list__task">
        <div :class="bem('tasks-list__icon', { type: 'energy' })" />

        <div
          class="tasks-list__title"
          v-html="$t('tasksList.getSquadEnergy.title')"
        />

        <ButtonAction
          class="tasks-list__button"
          color="yellow"
          icon="energy-green"
          :loading="squadCollectLoading"
          @click="handleCollectEnergy"
        >
          {{ collectSquadRewardButtonText }}
        </ButtonAction>
      </div>
      <div v-else class="tasks-list__task">
        <div :class="bem('tasks-list__icon', { type: 'energy' })" />

        <div class="tasks-list__title">
          <span
            v-html="
              $tc('tasksList.waitSquadEnergy.title', {
                value: constants.squadMembershipReward,
                icon: energyIconTemplate,
              })
            "
          ></span>
        </div>

        <ButtonAction
          :class="bem('tasks-list__button', { bigFont: true })"
          color="transparent"
        >
          <CountdownTimer :target-date="futureDate" />
        </ButtonAction>
      </div>
    </template>

    <Teleport to="body">
      <PopupInviteTerms v-if="termsOpened" @close="openTerms" />
      <PopupGetReward
        v-if="inviteReward"
        revardType="coins"
        :revard-value="inviteReward.data.amount"
        :loading="inviteCollectLoading"
        :text="
          $tc(
            'popupGetReward.rewards.friends',
            inviteReward.data.friendsCount,
            { value: inviteReward.data.friendsCount }
          )
        "
        @confirm="claimInviteReward"
      />
      <PopupGetReward
        v-if="subscriptionReward"
        :revard-value="subscriptionReward.data.amount"
        :loading="subscriptionCollectLoading"
        :text="$t('popupGetReward.rewards.telegramSubscribed')"
        @confirm="claimSubscriptionReward"
      />
    </Teleport>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ButtonAction from '@/components/ButtonAction.vue'
import PopupInviteTerms from '@/components/PopupInviteTerms.vue'
import PopupGetReward from '@/components/PopupGetReward.vue'
import { energyIconTemplate } from '@/lib/iconTemplates'
import CountdownTimer from '@/components/ContdownTimer.vue'
import { addSecondsToCurrentDate } from '@/lib/timeUtils'
import api from '@/api'
import eventBus from '@/lib/eventBus'

export default {
  name: 'TasksList',
  components: {
    CountdownTimer,
    PopupInviteTerms,
    PopupGetReward,
    ButtonAction,
  },
  emits: ['join-squad-click'],
  data: () => ({
    energyIconTemplate,
    termsOpened: false,
    futureDate: null,
    claimRewardInviteLoading: false,
    squadCollectLoading: false,
    inviteCollectLoading: false,
    subscriptionCollectLoading: false,
  }),
  computed: {
    ...mapGetters('user', ['isMember']),
    ...mapState('user', ['user']),
    ...mapState('notifications', ['notifications']),
    ...mapGetters('constants', ['constants']),
    ...mapGetters(['energyLootboxLink']),
    collectEnergyRemainingSeconds() {
      return this.user.collectEnergyRemainingSeconds
    },
    joinChannelButtonText() {
      return (
        this.$t('tasksList.joinChannel.buttonText') +
        ' +' +
        this.constants.subscriptionReward
      )
    },
    inviteFriendButtonText() {
      return (
        this.$t('tasksList.invite.buttonText') +
        ' +' +
        this.constants.inviteReward
      )
    },
    collectSquadRewardButtonText() {
      return (
        this.$t('tasksList.getSquadEnergy.buttonText') +
        ' +' +
        this.constants.squadMembershipReward
      )
    },
    subscriptionReward() {
      return this.notifications.find((i) => i.type === 'subscriptionReward')
    },
    inviteReward() {
      return this.notifications.find((i) => i.type === 'inviteReward')
    },
    joinChannelLoading() {
      return this.$store.state.joinChannelLoading
    },
  },
  watch: {
    collectEnergyRemainingSeconds: {
      handler() {
        this.setFutureDate()
      },
      immediate: true,
    },
  },
  methods: {
    getRemainingEnergyTime() {
      return this.$store.getters['user/getRemainingEnergyTime']()
    },
    async joinChannel() {
      this.$store.commit('setJoinChannelLoading', true)
      const { notifications } = await api.users.subscribeChannel()

      this.$store.dispatch('joinChannel')

      const interval = setInterval(() => {
        this.$store.commit('notifications/setNotifications', notifications)
        this.$store.commit('setJoinChannelLoading', false)
        clearInterval(interval)
      }, 8000)
    },
    joinChannelHandler() {
      this.joinChannelLoading
        ? this.$store.dispatch('joinChannel')
        : this.joinChannel()

      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'subscribeToHyperClicked',
      })
    },
    shareLink() {
      this.$store.dispatch('inviteFriend')
      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'inviteFriendsClicked',
      })
    },
    goToFarmEnegry() {
      window.open(this.energyLootboxLink, '_blank')
    },
    openTerms() {
      this.termsOpened = false
      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'inviteFriendsTermsClicked',
      })
    },
    async claimInviteReward() {
      this.inviteCollectLoading = true

      await api.notifications.deleteNotification(this.inviteReward.id)

      await this.$store.commit(
        'notifications/removeNotification',
        this.inviteReward.id
      )

      this.inviteCollectLoading = false

      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'inviteFriendsRewardReceived',
      })
    },
    async claimSubscriptionReward() {
      this.subscriptionCollectLoading = true

      await api.notifications.deleteNotification(this.subscriptionReward.id)

      await this.$store.commit(
        'notifications/removeNotification',
        this.subscriptionReward.id
      )

      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'subscribeToHyperRewardReceived',
      })

      this.$store.commit('user/setUserSubscriptionReward', true)

      this.subscriptionCollectLoading = false
    },
    async setFutureDate() {
      this.futureDate = addSecondsToCurrentDate(this.getRemainingEnergyTime())
    },
    async handleCollectEnergy() {
      this.squadCollectLoading = true
      await api.squads.collectEnergy()
      eventBus.emit('play-sound', 'collect_energy')
      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'energyCollected',
      })
      await this.$store.dispatch('user/fetchUser')
      this.squadCollectLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.tasks-list {
  &__task {
    display: flex;
    align-items: center;
    min-height: 70rem;
    padding: 10rem 0;

    &:not(:last-child) {
      border-bottom: 2px solid $color-cyan;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 35rem;
    height: 35rem;
    margin-right: 10rem;
    background: center no-repeat;
    background-size: contain;

    &_type {
      &_telegram {
        background-image: url(@/assets/icons/telegram-white.svg);
      }

      &_people {
        background-image: url(@/assets/icons/people.svg);
      }

      &_people-3 {
        background-image: url(@/assets/icons/people-3.svg);
      }

      &_energy {
        background-image: url(@/assets/icons/energy-green.svg);
        filter: drop-shadow(0px 0px 5px rgba($color-green, 0.5));
      }
    }
  }

  &__title {
    font-size: 15rem;
    font-weight: 500;
    line-height: 1.25;
    margin-right: 10rem;
  }

  &__title-extra {
    margin-top: 5rem;
    border-bottom: 1px dashed;
    font-size: 16rem;
    font-weight: normal;
    line-height: 1.25;
    color: rgba(white, 0.75);
  }

  &__button {
    flex-shrink: 0;
    margin-left: auto;
    width: 155rem;

    &_big-font {
      font-size: 14rem;
    }

    &_join-loading {
      pointer-events: initial !important;
    }
  }

  &__button-join-squad {
    flex-shrink: 0;
    width: 40rem;
    height: 40rem;
    margin-left: auto;
    border-radius: 4rem;
    background: $color-cyan-dark url(@/assets/icons/arrow-right-white.svg)
      no-repeat center;
    background-size: 20rem auto;
  }
}
</style>
