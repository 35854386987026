import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import * as amplitude from '@amplitude/analytics-browser'

const state: EventManagerState = {
  userId: '',
}

const getters: GetterTree<EventManagerState, any> = {
  getUserId(state): string {
    return state.userId
  },
}

const mutations: MutationTree<EventManagerState> = {
  SET_USER_ID(state, userId: string) {
    state.userId = userId
  },
}

const actions: ActionTree<EventManagerState, any> = {
  async initEventManager({ commit }, userId: string) {
    commit('SET_USER_ID', userId)
    try {
      await amplitude.init(process.env.VUE_APP_AMPLITUDE_API_KEY, userId, {
        defaultTracking: true,
        minIdLength: 1,
      }).promise
    } catch (error) {
      console.error(error)
    }
  },
  trackEvent(
    { state },
    {
      eventType,
      eventProperties,
    }: { eventType: string; eventProperties: EventProperties }
  ) {
    amplitude.logEvent(eventType, { ...eventProperties, userId: state.userId })

    if (window && window.gtag) {
      window.gtag('event', eventType, {
        ...eventProperties,
        user_id: state.userId,
      })
    } else {
      console.error('GTM is not initialized')
    }
  },
}

const amplitudeModule: Module<EventManagerState, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default amplitudeModule
