<template>
  <div class="no-squad-info">
    <ButtonAction
      class="no-squad-info__button-create"
      size="big"
      color="cyan"
      @click="$emit('newSquad')"
    >
      {{ $t('noSquadInfo.buttonCreateSquad') }}
    </ButtonAction>

    <div class="no-squad-info__or">
      {{ $t('noSquadInfo.or') }}
    </div>

    <div
      class="no-squad-info__join-squad-text"
      v-html="
        $t('noSquadInfo.joinSquadText', {
          icon: energyIconTemplate,
          reward: constants.squadMembershipReward,
        })
      "
    />
  </div>
</template>

<script>
import ButtonAction from '@/components/ButtonAction.vue'
import { energyIconTemplate } from '@/lib/iconTemplates'
import { mapGetters } from 'vuex'

export default {
  name: 'NoSquadInfo',
  components: { ButtonAction },
  emits: ['newSquad'],
  data: () => ({
    energyIconTemplate,
  }),
  computed: {
    ...mapGetters('constants', ['constants']),
  },
}
</script>

<style lang="scss">
.no-squad-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button-create {
    width: 100%;
    margin-bottom: 16rem;
  }

  &__or {
    margin-bottom: 8rem;
    font-size: 18rem;
    font-weight: bold;
    line-height: 1.4;
    text-transform: uppercase;
  }

  &__join-squad-text {
    font-size: 16rem;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
  }
}
</style>
