<template>
  <div class="squads-list">
    <div class="squads-list__my-squad">
      <template v-if="isMember">
        <HeadLined class="squads-list__head">
          {{ $t('squadsList.yourSquadHead') }}
        </HeadLined>

        <SquadItem
          class="squads-list__your-squad"
          :squad="user.owningSquad || user.membershipSquad"
          :can-leave="!isSquadOwner"
        />
      </template>
      <NoSquadInfo
        v-else
        class="squads-list__no-squad-info"
        @new-squad="squadCreationOpened = true"
      />
    </div>

    <HeadDoted class="squads-list__header-dotted">
      <template #text>
        <span
          class="squads-list__header-link"
          @click="handleOpenCompletition()"
          >{{ $t('squadsList.competition') }}</span
        ></template
      >
      <template #action>
        <CountdownTimer class="squads-list__timer" :target-date="futureDate" />
      </template>
    </HeadDoted>

    <div class="squads-list__poster-container">
      <div class="squads-list__header-link" href="https://t.me/hyper_coin/24">
        <img
          @click="handleOpenCompletition()"
          class="squads-list__poster"
          src="@/assets/images/poster-10-m.jpg"
          alt="poster-10-m"
        />
      </div>
    </div>

    <HeadDoted class="squads-list__header-dotted">
      <template #text>{{ $t('squadsList.listHead') }}</template>
      <template #action>
        <ButtonSwithcer v-model="selectedType" :items="sortList" />
      </template>
    </HeadDoted>

    <div class="squads-list__items-loader" v-if="ladingSquads">
      <SpinnerBlock class="squads-list__spinner-block" />
    </div>
    <div class="squads-list__items-list" v-else>
      <SquadItem
        v-for="squad in activeTopList"
        class="squads-list__item"
        :squad="squad"
        :key="squad.id"
        :can-join="!isMember"
        @click="openedSquad = squad"
      />
    </div>

    <Teleport to="body">
      <PopupNewSquad
        v-if="squadCreationOpened"
        @close="squadCreationOpened = false"
        @squad-created="onSquadCreated"
      />
    </Teleport>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import SquadItem from '@/components/SquadItem.vue'
import PopupNewSquad from '@/components/PopupNewSquad.vue'
import HeadLined from '@/components/HeadLined.vue'
import NoSquadInfo from '@/components/NoSquadInfo.vue'
import HeadDoted from './HeadDoted.vue'
import ButtonSwithcer from './ButtonSwithcer.vue'
import SpinnerBlock from './SpinnerBlock.vue'
import CountdownTimer from '@/components/ContdownTimer.vue'

export default {
  name: 'SquadsList',
  components: {
    NoSquadInfo,
    HeadLined,
    PopupNewSquad,
    SquadItem,
    HeadDoted,
    ButtonSwithcer,
    SpinnerBlock,
    CountdownTimer,
  },
  emmits: ['squad-created'],
  data: () => ({
    energyIconTemplate: '<div class="_energy-icon"></div>',
    openedSquad: null,
    squadCreationOpened: false,
    creationSquadPrice: 100,
    ladingSquads: true,
    squads: [],
    selectedType: null,
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapState('squadsTop', ['activeType']),
    ...mapGetters('user', ['isSquadOwner', 'isMember']),
    ...mapGetters('squadsTop', ['activeTopList', 'getSquadPosition']),
    sortList() {
      return [
        {
          id: 'daily',
          title: this.$t('sort.today'),
        },
        {
          id: 'weekly',
          title: this.$t('sort.week'),
        },
      ]
    },
    squadInTopPosition() {
      const squadId =
        this.user?.owningSquad?.id || this.user?.membershipSquad?.id
      return squadId ? this.getSquadPosition(squadId) : null
    },
  },
  watch: {
    selectedType: {
      async handler(type) {
        this.setActiveType(type)
        await this.loadSquads()
      },
    },
  },
  methods: {
    ...mapActions('squadsTop', ['fetchActiveTop']),
    ...mapMutations('squadsTop', ['setActiveType']),
    onSquadCreated() {
      this.$emit('squad-created')
      this.squadCreationOpened = false
    },
    async loadSquads() {
      this.ladingSquads = true
      await this.fetchActiveTop()
      this.ladingSquads = false
    },
    handleOpenCompletition() {
      window.open('https://t.me/hyper_coin/24', '_blank')
    },
  },
  async created() {
    this.selectedType = this.activeType
    this.futureDate = '2024-06-30T12:00:00+03:00'
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.squads-list {
  --padding-x: 16rem;

  &__head {
    margin: 20rem var(--padding-x) 8rem;
  }

  &__your-squad {
    margin: 0;
  }

  &__no-squad-info {
    margin: 18rem var(--padding-x) 22rem;
  }

  &__list-head {
    margin: 0 var(--padding-x);
  }

  &__items-list {
    margin: 0 var(--padding-x);
  }

  &__item {
    border-bottom: 2px solid rgba(white, 0.6);
  }

  &__my-squad {
    border-bottom: 2px solid $color-cyan;
    margin: 0 12rem 22rem 12rem;
  }

  &__header-dotted {
    margin-bottom: 7rem;
  }

  &__items-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 87rem;
  }

  &__spinner-block {
    font-size: 20rem;
  }

  &__poster-container {
    padding: 10rem 20rem 30rem;
  }

  &__poster {
    width: 100%;
    border-radius: 24rem;
  }

  &__header-link {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
