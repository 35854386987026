import { t } from './i18n'

export enum SquadLevels {
  SQUAD_LEVEL_BRONZE = 'bronze',
  SQUAD_LEVEL_SILVER = 'silver',
  SQUAD_LEVEL_GOLD = 'gold',
  SQUAD_LEVEL_DIAMOND = 'diamon',
}

export function getSquadLevelTitle(levelId: SquadLevels) {
  const map = {
    [SquadLevels.SQUAD_LEVEL_BRONZE]: t('tools.squadLevels.bronze'),
    [SquadLevels.SQUAD_LEVEL_SILVER]: t('tools.squadLevels.silver'),
    [SquadLevels.SQUAD_LEVEL_GOLD]: t('tools.squadLevels.gold'),
    [SquadLevels.SQUAD_LEVEL_DIAMOND]: t('tools.squadLevels.diamond'),
  }

  return map[levelId]
}
