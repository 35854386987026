import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/main.scss'
import globalMixin from '@/mixins/global'
import { i18n } from './lib/i18n'
import SquadItem from '@/components/SquadItem.vue'
import * as Sentry from '@sentry/vue'

const app = createApp(App)

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', process.env.VUE_APP_API_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

if (window.Telegram.WebApp.initDataUnsafe.user) {
  Sentry.setUser({
    username: `${window.Telegram.WebApp.initDataUnsafe.user.id}`,
  })
}

// пришлось регистрировать глобально, потому что требуется рекурсивный импорт, SquadItem -> PopupLeaveSquad -> SquadItem
// если импортировать внутри PopupLeaveSquad, то при импорте возвращается undefined
app.component('SquadItem', SquadItem)

app.config.errorHandler = (err, vm) => {
  console.error(err)

  store.dispatch('errors/addError', err)

  if (vm && vm.$router) {
    vm.$router.push({ name: 'error' })
  }
}

app.mixin(globalMixin).use(store).use(router).use(i18n).mount('#app')
